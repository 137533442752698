.how-it-works {
    background-color: #EAEFF5;
    width: 100%;
    padding: 100px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.how-it-works-container {
    width: 100%;
    max-width: 1200px;
    text-align: center;
}

.how-header-row {
    margin-bottom: 50px;
}

.how-header {
    font-family: "DM Sans", sans-serif;
    font-weight: 700;
    color: #416FDF;
    font-size: 25px;
    line-height: 36px;
}

.how-header-2 {
    font-family: "DM Sans", sans-serif;
    color: #2A2A2A;
    font-size: 41px;
    line-height: 54px;
    font-weight: 700;
}

.how-header-text {
    font-family: "DM Sans", sans-serif;
    color: #4B4B4B;
    text-align: justify;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 30px;
}

.how-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
}



.icon-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 20px;
    animation: fade-in 0.5s ease-out;
    min-height: 200px;
}

.icon-step img {
    margin-bottom: 10px;
    /* Add spacing between icon and text */
}

.icon-desc {
    margin-top: 10px;
    /* Ensure some margin between icon and description */
    font-family: "DM Sans", sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: #333;
    text-align: center;
}

/* Optional: reduce the size of arrows to look better on mobile */
.arrow {
    width: 50px;
    /* Slightly smaller */
    height: auto;
}


.arrow-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow {
    width: 60px;
    height: auto;
}

.game-image,
.computer-vision,
.ml-model,
.raw-data {
    width: 120px;
    height: auto;
    border-radius: 8px;
    transition: transform 0.3s;
}

.game-image:hover,
.computer-vision:hover,
.ml-model:hover,
.raw-data:hover {
    transform: scale(1.1);
}

.game-text,
.computer-text,
.ml-text,
.raw-text {
    font-family: "DM Sans", sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: #333;
    margin-top: 10px;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .how-it-works {
        padding: 50px 10px;
    }

    .how-icons {
        flex-direction: column;
        align-items: center;
    }

    .icon-step {
        margin: 20px 0;
    }

    .arrow {
        width: 40px;
    }

    .how-header {
        font-size: 22px;
    }

    .how-header-2 {
        font-size: 36px;
    }

    .how-header-text {
        font-size: 16px;
        width: 90%;
    }

    .down-arrow {
        display: block;
    }

    .right-arrow {
        display: none;
    }
}

@media (min-width: 768px) {
    .down-arrow {
        display: none;
    }

    .right-arrow {
        display: block;
    }
}

.step-img-container {
    height: 135px;
    align-content: center;
}