.footer-hold {
  background-color: #1c1c1c;
  color: #f0f0f0;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.footer-rimble-header {
  font-size: 36px;
  font-weight: 700;
  color: #00ffc6;
  margin-top: 0;
}

.footer-links-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
}

.footer-links {
  margin: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 200px;
}

.footer-text-links {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.address-footer {
  font-size: 0.88em;
}

.footer-link {
  color: #f0f0f0;
  text-decoration: none;
  font-size: 16px;
  margin: 5px 0;
  transition: color 0.3s;
}

.footer-link:hover {
  color: #00ffc6;
}

.footer-bottom {
  text-align: center;
  width: 100%;
}

.footer-text {
  font-size: 14px;
  color: #aaa;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-links-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-links {
    width: 100%;
  }

  .footer-bottom {
    flex-direction: column;
    align-items: center;
  }

  .footer-bottom {
    text-align: left;
    width: 100%;
    margin-top: 25px;
  }
}

@media (min-width: 768px) {
  .footer-links {
    width: 180px;
  }
}