.supported-games {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 2rem 1rem;
    background-color: #18191c;
    /* Dark background */
}

.supported-games-container {
    display: flex;
    flex-direction: column;
    /* Stack header and content vertically */
    align-items: center;
    /* Center elements */
    max-width: 1200px;
    margin: 0 auto;
    color: #ffffff;
    /* White text for contrast */
}

.sg-header-container {
    text-align: center;
    width: 100%;
    margin-bottom: 1.5rem;
}

.sg-header {
    font-family: "DM Sans", sans-serif;
    font-weight: 700;
    color: #1abc9c;
    font-size: 1.5rem;
}

.sg-header-2 {
    font-family: "DM Sans", sans-serif;
    color: #ffffff;
    font-size: 2rem;
}

.sg-header-text {
    font-family: "DM Sans", sans-serif;
    font-size: 1rem;
    color: #b0bec5;
    /* Light gray for descriptive text */
    margin-bottom: 1.5rem;
}

.sg-content-container {
    display: flex;
    align-items: center;
    width: 100%;
    /* Ensure full width */
}

.sg-icons {
    display: grid;
    /* Use grid layout */
    grid-template-columns: repeat(3, 1fr);
    /* Three columns in the grid */
    gap: 1rem;
    /* Space between grid items */
    justify-items: center;
    /* Center items in their grid cells */
    width: 100%;
    /* Full width for icons */
    margin-top: 1.5rem;
    /* Add space above icons to separate from header */
    margin-right: 50px;
}

.game-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s;
    padding-bottom: 20px;
}

.game-icon-container p {
    margin-top: -2px;
}

.game-card p:last-child {
    margin-bottom: 0;
}

.game-icon-container:hover {
    transform: scale(1.05);
}

.game-icon {
    width: 80px;
    height: auto;
    border-radius: 12px;
}

.game-icon-a {
    filter: brightness(1.3);
}

.game-info-text {
    font-family: "DM Sans", sans-serif;
    font-weight: 500;
    color: #ffffff;
    /* White for contrast */
}

.game-info-text-a {
    font-weight: 700;
    color: #1abc9c;
    /* Light green for active game */
}

.game-card-container {
    width: 65%;
    /* Set width for the game card */
    display: flex;
    justify-content: center;
    /* Center the card */
    margin-top: 1.5rem;
    /* Space between icons and card */
}

.game-card {
    background-color: #2c2f33;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    padding: 20px;
    text-align: center;
    /* transition: transform 0.2s, box-shadow 0.2s; */
    max-width: 100%;
    /* animation: pulse 2s infinite; */
    /* Pulsing effect */
}

/* Media query for responsive design */
@media (max-width: 768px) {
    .sg-content-container {
        flex-direction: column;
        /* Stack elements on small screens */
    }

    .sg-icons {
        grid-template-columns: repeat(2, 1fr);
        /* Two columns on smaller screens */
        width: 100%;
        /* Full width for icons */
    }

    .game-card-container {
        width: 100%;
        /* Full width for the game card */
        margin-top: 2rem;
        /* Space between icons and card */
    }
}

/* Pulsing effect for game cards */
@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
        /* Scale up slightly */
    }

    100% {
        transform: scale(1);
    }
}