/* General Layout and Background */
.our-vision {
    background: linear-gradient(135deg, #111322 0%, #1a1f42 100%);
    color: #f1f1f1 !important;
    /* Light text color for all text */
    font-family: 'Roboto', sans-serif;
    padding: 60px 0;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.our-vision * {
    color: inherit !important;
    text-align: center;
}

/* Container Settings */
.our-vision-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    position: relative;
    z-index: 2;
}

.about-header-container {
    margin-bottom: 40px;
    text-align: center;
}

.about-header-3 {
    font-size: 3rem;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    color: #ffffff !important;
    margin-bottom: 35px;
    animation: pulse 3s infinite ease-in-out;
    /* Gentle pulse animation */
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
        /* Slightly enlarge */
    }

    100% {
        transform: scale(1);
    }
}

/* Text Description */
.about-header-text {
    font-size: 1.1rem;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    line-height: 1.6;
    color: #dcdcdc;
    max-width: 800px;
    margin: 0 auto;
    padding-bottom: 20px;
    text-align: center;
}

/* Media Queries */
@media (max-width: 768px) {
    .our-vision-container {
        padding: 10px;
    }

    .about-header-3 {
        font-size: 2.4rem;
    }

    .about-header-text {
        font-size: 1rem;
        padding: 0 15px;
    }
}

@media (max-width: 480px) {
    .about-header-3 {
        font-size: 2rem;
    }

    .about-header-text {
        font-size: 0.9rem;
        line-height: 1.5;
    }
}