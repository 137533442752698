.about {
    display: flex;
    justify-content: center;
}

.about-header {

    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    letter-spacing: 0.25px;
    width: 800px;
    margin-top: 93px;
    padding-bottom: 50px;
}

.about-header-2 {

    text-align: left;
    font-weight: 700;
    color: #416FDF;
    font-size: 25px;
    font-style: normal;
    line-height: 36px;
    width: 100%;
    margin: 0;
}

.about-header-text {

    color: #000000;
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    text-align: left;
}