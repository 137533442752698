html {
    font-family: "DM Sans";
}


/*  Dashboard Table */

.dash-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 8%;
}

/*  Dashboard Navigation */

.dashboard-nav {
    display: flex;
    margin-top: 5%;
    justify-content: flex-end;

}

.dashboard-timeframe-container {
    width: 26%;
    text-align: left;
    margin-right: 10%;
}

.dashboard-timeframe-header {
    margin: 0;
    text-transform: capitalize;
}


.dashboard-timeframe-text {
    font-weight: 700;
    margin-right: 5%;

    font-size: 20px;
    text-decoration: none;
}

.dashboard-timeframe-text:hover {
    cursor: pointer;
    text-decoration: underline;
}

.dashboard-timeframe-text-a {

    text-align: center;
    font-weight: 700;
    width: 50%;
    min-width: 100px;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 5%;
    font-size: 20px;
    text-decoration: none;
    background-color: #A0ACEF;
    border-radius: 10px;

}

.dashboard-timeframe-text-a:hover {
    cursor: pointer;
    text-decoration: underline;
}

.dashboard-timeframe {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
}

.gametype {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    width: 75%;
    /* height: 50%; */
    align-items: center;

}

.gametype-dash-container {
    width: 70px;
    height: 70px;
    margin-left: 5%;
}

/* Dashboard Data */

.dash-data {
    width: 60%;
    margin-bottom: 10%;
}

.dash-data-none {
    display: none;
}

.dash-match-rows {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

}

.dash-match-rows-container {
    width: 100%;
    flex: 0 0 45%;
    margin-right: 5%;
    margin-top: 2%;

}

.dash-row-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 15vh;
}

.dash-row-container:hover {
    background-color: #F6F6FD;
    cursor: pointer;
}

.dash-data-2 {
    border-bottom: 0.9px solid #ABABAB;
    width: 100%;
    justify-content: center;

}

.dash-data-container {
    width: 100%;
    height: 15vh;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dash-date {
    font-size: 14px;
}

/* Hide div based on matching date */

.dash-match-rows-container-none {
    display: none;
}

.dash-match-header {
    text-align: left;
    font-size: 20px;
}

.dash-match-header-2 {
    text-align: left;
    font-size: 18px;
    color: #416FDF;
}

/* Dash Match Info Container */

.dash-times {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
}

.dash-teams {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
}

.dash-teams-container {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    margin: 0;
    margin-top: 10%;
}


.dash-dot-container {
    display: flex;
    flex-direction: column;
    width: 30%;
    margin: 0;
    margin-top: 2%;
}

.dash-match-info {
    margin-left: 0;
    margin-right: 0;

}

.dash-match-links {
    text-align: left;
    text-decoration: none;
    color: black;
    font-size: 14px;
}

.dash-scores {
    margin: 0;
    margin-top: 1%;
    margin-left: 2%;
    margin-right: 5%;


}

.dash-scores-container {
    height: 100%;
}

/* Dash Info text */

.dash-teams-text {
    margin: 0;
    text-align: left;
    font-size: 12px;
    letter-spacing: 0.35px;

}

.dash-league-text {
    color: rgba(111, 110, 114, 1);
    margin: 0;
    text-align: left;
    font-size: 12px;
    letter-spacing: 0.35px;
}

.dash-time-status {
    width: 100%;
}

.live-status {
    width: 50%;
    color: white;
    background: rgba(238, 88, 65, 1);
    font-weight: 700;
    border-radius: 6px;

}

.dash-time-status-text {
    font-family: "DM Sans";
}

.scores {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    margin-top: 90%;

}

.dash-text-header {
    margin: 0;
    text-align: left;
    font-size: 14px;
}

.dash-rocketleague {
    width: 62px;
    height: auto;
    /*filter: grayscale(1);*/
}

.dash-rocketleague:hover {
    width: 67px;
}

.dash-cod {
    width: 62px;
    height: auto;
    /*filter: grayscale(1);*/
}

.dash-cod:hover {
    width: 67px;
}

.dash-nba {
    width: 26px;
    height: auto;
    /*filter: grayscale(1);*/
}

.dash-nba:hover {
    width: 28px;
}

.dash-cricket {
    width: 62px;
    height: auto;
}

.dash-cricket:hover {
    width: 67px;
}

.dash-kabaddi {
    width: 62px;
    height: auto;
}

.dash-kabaddi:hover {
    width: 67px;
}

.dash-soccer {
    width: 90px;
    height: auto;
    padding-top: 8%;
    margin-left: -25%;
}

.dash-soccer:hover {
    width: 95px;
}

.dash-lol {
    width: 52.95px;
    height: auto;
    /*filter: grayscale(1);*/
}

.dash-lol:hover {
    width: 58px;
}

.dash-lol-a {
    width: 52.95px;
    height: auto;
}

.dash-lol-a:hover {
    width: 58px;
}

.dash-dota2 {
    width: 70px;
    height: auto;
    /*filter: grayscale(1);*/
}

.dash-dota2:hover {
    width: 75px;
}

.dash-dota2-a {
    width: 70px;
    height: auto;

}

.dash-dota2-a:hover {
    width: 75px;
}

.dash-csgo {
    width: 63px;
    height: auto;
    /*filter: grayscale(1);*/
}

.dash-csgo:hover {
    width: 68px;
}

.dash-csgo-a {
    width: 63px;
    height: auto;
}

.dash-csgo-a:hover {
    width: 68px;
}


.dash-val {
    width: 90px;
    height: auto;
    /*filter: grayscale(1);*/
}

.dash-val:hover {
    width: 100px;
}

.dash-val-a {
    width: 90px;
    height: auto;
}

.dash-val-a:hover {
    width: 100px;
}

.dash-dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    margin: 0;
    margin-top: 20%;
}

.dash-text {
    margin: 0;
}


.dash-line {
    border: 0.53357px solid #ABABAB;
    background-color: #ABABAB;
}


/* Filter by Tournament List */

.dashboard-filter {
    display: flex;
    justify-content: flex-end;
    width: 80%;
    margin-top: 2%;
}

.dashboard-filter-dropdown {
    background: rgba(118, 118, 128, 0.12);
    height: 30px;
    border-radius: 5px 0px 0px 5px;
}

.dashboard-filter-dropdown-menu {
    background: #F6F6FD;
    height: 30px;
    border-radius: 5px 0px 0px 5px;
}

nav ul {
    width: 100%;
    margin: 0;
    padding: 0px 10px;

}

nav ul li {
    float: left;
    list-style: none;
    position: relative;
}

nav ul li a {
    display: block;
    font-family: "DM Sans";
    padding: 5px 20px;
    text-decoration: none;
    color: black;

}

nav ul li ul {
    display: none;
    position: absolute;
    background-color: white;
    margin-top: 6%;
    border-radius: 4px;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.12);
    border: 1px solid rgba(63, 67, 80, 0.16)
}

nav ul li .menu {
    display: block;
    width: 200px;
    text-align: left;
    padding: 0;
}

nav ul li .no-menu {
    display: none;

}

nav ul li ul li {
    width: 100%;
    font-size: 12px;
    border-radius: 4px;

}

nav ul li ul li a:hover {
    background-color: #f3f3f3;
}


/* Arrow Down for Filter */

.arrow-down-container {
    background: rgba(118, 118, 128, 0.12);
    width: 20px;
    height: 30px;
    margin: 0;
    cursor: pointer;
    border-radius: 0px 5px 5px 0px;
}

.arrow-down-container-menu {
    background: #F6F6FD;
    width: 20px;
    height: 30px;
    margin: 0;
    cursor: pointer;
    border-radius: 0px 5px 5px 0px;
}

.dash-arrow {
    margin-top: 6px;
    display: block;
    padding: 4px 4px;
    width: 1px;
    height: 1px;
    background: transparent;
    border-top: 2px solid #bfbfbf;
    border-left: 2px solid #bfbfbf;
    text-decoration: none;
    color: transparent;
}

.dash-arrow.down {
    transform: rotate(-135deg);

    right: 175px;
}

.filter-links {
    color: black;
    cursor: pointer;
    font-family: "DM Sans";
}

/* Colors */

.team_1_color {
    background-color: rgba(65, 111, 223, 1);

}

.team_2_color {
    background-color: rgba(46, 73, 147, 1);
}

/* Left Back Arrow */

.arrow-container {
    margin-right: 2%;
}

.arrow-link {
    width: 10px;
    min-width: 10px;
    margin-right: 2%;
}

.left-arrow {
    top: 0;
    bottom: 0;
    width: 50px;
    margin-right: 2%;
    background: transparent;
    background-image: none;
    display: block;
}

/* Dash Loading Screen */

.dash-loading-container {
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dash-loading-header {
    font-family: "DM Sans";
    font-size: 20px;
    font-weight: 500;
}

.dashboard-auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
    background-color: #1a1a1a;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.auth-message {
    font-size: 20px;
    color: #fff;
    margin-bottom: 15px;
    text-align: center;
}

.auth-input {
    width: 100%;
    padding: 10px 15px;
    margin-bottom: 15px;
    border: 2px solid #444;
    border-radius: 5px;
    background-color: #2c2c2c;
    color: #fff;
    transition: border-color 0.3s;
}

.auth-input:focus {
    border-color: #666;
    outline: none;
}

.auth-submit-btn {
    width: 100%;
    padding: 10px 0;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.auth-submit-btn:hover {
    background-color: #0056b3;
}

@media (max-width: 480px) {
    .dashboard-auth-container {
        padding: 15px;
    }

    .auth-message {
        font-size: 18px;
    }

    .auth-submit-btn {
        padding: 12px 0;
    }
}