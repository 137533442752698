body {
    font-family: "DM Sans";
}


.progress-bar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 15vh;
    background-color: rgba(246, 246, 246, 1);
    border-radius: 38px;
    margin: 0;
}

.progress-bar {
    margin-left: 5%;
    margin-right: 5%;
}

.CircularProgressbar {
    width: 125px;
    height: 125px;
}


.CircularProgressbar .CircularProgressbar-text {
    font-size: 24px;
    dominant-baseline: middle;
    text-anchor: middle;
    fill: rgba(130, 130, 130, 1);
}

