/* Container Styling */

.main-container {
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 80%;
    background-color: #f9f9f9;
}

/* Titles */

.dash-match-title {
    margin: 5% 10%;
    text-align: left;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
}

.dash-match-title-2,
.dash-match-title-3 {
    font-size: 20px;
    margin-top: 10px;
}

.dash-completed-header {
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-top: 30px;
    color: #416FDF;
}

/* VS Header */

.dash-match-vs-header-container {
    display: flex;
    justify-content: center;
    margin-top: 2%;
}

.dash-match-vs-header {
    width: 30%;
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #416FDF;
}

/* Final Score */

.dash-final-score-container {
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding-top: 15px;
}

.dash-final-scores {
    font-size: 5vw;
    width: 30%;
    color: #2E4993;
    font-weight: bold;
}

/* Table Styling */

table {
    width: 90%;
    margin: 20px auto;
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

th,
td {
    padding: 15px;
    text-align: center;
    font-size: 16px;
    color: #333;
    border-bottom: 1px solid #ddd;
}

th {
    background-color: #416FDF;
    color: white;
    font-weight: bold;
}

td {
    background-color: #fff;
}

tr:nth-child(even) td {
    background-color: #F4F4F4;
}

tr:hover td {
    background-color: #e0e7ff;
}

/* Table Column Headers */

.dash-team1-column-header {
    background-color: #416FDF;
    color: white;
}

.dash-team2-column-header {
    background-color: #2E4993;
    color: white;
}

/* Select Dropdowns */

select {
    width: 90%;
    max-width: 250px;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f1f1f1;
    color: #333;
    margin: 10px 0;
    cursor: pointer;
    float: right;
}

/* Player Links */

.dash-player-link,
.dash-player-link-header {
    font-size: 18px;
    color: #416FDF;
    text-decoration: none;
    font-weight: 600;
}

.dash-player-link:hover,
.dash-player-link-header:hover {
    font-weight: 700;
    text-decoration: underline;
}

/* Circle Indicators */

.dash-team-dot {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 10px;
}

.team_1_color {
    background-color: rgba(65, 111, 223, 1);
}

.team_2_color {
    background-color: rgba(46, 73, 147, 1);
}

.team_1_color_text {
    color: rgba(65, 111, 223, 1);
}

.team_2_color_text {
    color: rgba(46, 73, 147, 1);
}

/* Win Probability */

.dash-win-probability-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
}

.win-probability-team-name {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
}

.win-probability-team-text {
    font-size: 24px;
    color: rgba(130, 130, 130, 1);
}

/* GridJS Styling */

.gridjs th {
    padding: 0 10px;
    background-color: #f9f9f9;
}

.gridjs-table th,
.gridjs-table td {
    padding: 15px;
    border: 1px solid #ddd;
}

.gridjs-container {
    width: 90%;
    margin: 20px auto;
}

.main-body {
    margin-left: 40px;
    margin-right: 40px;
}