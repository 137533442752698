.about {
    display: flex;
    justify-content: center;
}

.about-header {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    letter-spacing: 0.25px;
    width: 800px;
    margin-top: 93px;
    padding-bottom: 50px;
}

.about-header-3 {
    font-weight: 700;
    color: #416FDF;
    font-size: 28px;
    line-height: 36px;
    margin: 0;
}

.about-header-text {
    color: #000000;
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    text-align: left;
}

.privacy-policy-section {
    margin-top: 50px;
}

.terms-header {
    text-align: center;
}