.navbar-brand {}

.navbar {
    background-color: #0c0f15;
    color: #f1f1f1;
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.navbar-brand {
    font-family: 'Orkney', sans-serif;
    font-size: 2.5rem;
}

.navbar-brand a {
    color: #00ffc6;
    font-weight: bold;
    text-decoration: none;
}

.navbar-brand a:hover {
    opacity: 0.7;

}

.navbar-links {
    display: flex;
    gap: 2rem;
    /* Space between links */
    transition: max-height 0.3s ease-in-out;
}

.navbar-link {
    color: #f1f1f1;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.15rem;
}

.navbar-link:hover {
    color: #00ffc6;
    /* Example hover color */
}

/* Toggle Button Styles */
.navbar-toggle {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.bar {
    height: 3px;
    width: 25px;
    background-color: #f1f1f1;
    margin: 4px 0;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .navbar-links {
        display: none;
        /* Hide links by default */
        flex-direction: column;
        /* Stack links vertically */
        position: absolute;
        /* Position links absolutely */
        top: 60px;
        /* Position below the navbar */
        left: 0;
        /* Align to the left */
        background-color: #0c0f15;
        /* Match navbar color */
        width: 100%;
        /* Full width */
        padding: 1rem;
        /* Add padding */
        z-index: 1;
        /* Bring links in front */
        opacity: 0.88;
    }

    .navbar-links.active {
        display: flex;
        /* Show links when active */
    }

    .navbar-toggle {
        display: flex;
        /* Show toggle button on mobile */
    }
}