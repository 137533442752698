.button-container {
    display: flex;
    /* Use flexbox layout */
    justify-content: space-between;
    /* Arrange buttons with space between them */
    padding: 16px;
    align-items: center;
    position: absolute;
    width: 18%;
    right: 0;
    z-index: 1;
    margin-right: 30px;
}

.button-container button {
    padding: 8px 12px;
    background-color: #9e9e9e;
    /* border: 1px solid #ccc; */
    color: white;
    border-radius: 8px;
    border: 0;
    font-family: 'Monsterret', sans-serif;
    font-size: 12px;
}

.button-container button.active-button {
    background-color: #2F80ED;
}


.dash-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 8%;
}

.dash-data {
    width: 60%;
    margin-bottom: 10%;
}