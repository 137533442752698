.game-card {
    background-color: #2c2f33;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    padding: 20px;
    text-align: center;
    transition: transform 0.2s, box-shadow 0.2s;
    width: 550px;
}

.game-card:hover {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.7);
}

.game-card-title {
    font-family: "DM Sans", sans-serif;
    font-size: 1.8rem;
    color: #1abc9c;
    margin-bottom: 1rem;
    margin-top: 8%;
}

.game-card-info {
    display: flex;
    justify-content: space-around;
    margin-top: 5%;
}

.game-card-stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33%;
}

.stat-value {
    font-size: 1.5rem;
    font-weight: 700;
    color: #ffffff;
    /* White for contrast */
}

.stat-label {
    font-size: 0.9rem;
    color: #b0bec5;
    /* Light gray for labels */
    margin-top: 0.3rem;
    width: 80%;
}


@media (min-width: 768px) {
    .game-card {
        height: 250px;
    }
}

@media (max-width: 768px) {
    .game-card {
        max-width: 260px;
    }
}