html,
body {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

a:hover {
    text-decoration: none !important;
}

.home-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #0c0f15;
    /* Updated for esports theme */
    color: #ffffff;
    padding: 20px;
    overflow: hidden;
    /* Prevent overflow */
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slide-in-left {
    from {
        transform: translateX(-100%);
        /* Start off-screen */
        opacity: 0;
        /* Start invisible */
    }

    to {
        transform: translateX(0);
        /* End in position */
        opacity: 1;
        /* Fade in */
    }
}

@keyframes zoom-in {
    from {
        transform: scale(0.8);
        /* Start smaller */
        opacity: 0;
        /* Start invisible */
    }

    to {
        transform: scale(1);
        /* End in normal size */
        opacity: 1;
        /* Fade in */
    }
}

.home-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;

}

.rb-home-computer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: zoom-in 1s ease-out;
    /* Apply zoom-in animation */
}

.rb-home-computer {
    width: 100%;
    max-width: 450px;
    height: auto;
    object-fit: contain;
}

.rb-header-text-container {
    text-align: left;
    max-width: 50%;
    animation: slide-in-left 1s ease-out;
    /* Apply slide-in animation */
}

.rb-home-heading {
    font-family: "DM Sans", sans-serif;
    font-weight: bold;
    font-size: 42px;
    line-height: 54px;
    margin: 0;
    transition: transform 0.3s ease;
    text-align: left;
}

.rb-home-text {
    margin: 20px 0;
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #dcdcdc;
}

.home-header-links {
    display: flex;
    gap: 10px;
    margin-top: 25px;
}

.rb-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "DM Sans", sans-serif;
    font-weight: 900;
    font-size: 20px;
    color: white;
    background: #416FDF;
    border: none;
    border-radius: 28px;
    padding: 10px 20px;
    transition: background 0.3s;
    text-decoration: none;
    /* Remove underline */
}

.rb-button:hover {
    background: #3256B5;
}

.learn-more {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    font-family: "DM Sans", sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: #00ffc6;
    background: transparent;
    border: 2px solid #00ffc6;
    border-radius: 28px;
    transition: color 0.3s, background 0.3s;
    text-decoration: none;
    /* Remove underline */
}

.learn-more:hover {
    background: #00ffc6;
    color: #0c0f15;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
    .home-header-container {
        flex-direction: column;
        align-items: center;
        padding-top: 40px;
    }

    .rb-header-text-container {
        max-width: 100%;
        text-align: center;
    }

    .rb-home-heading {
        font-size: 32px;
    }

    .rb-home-text {
        font-size: 16px;
    }

    .rb-home-computer {
        max-width: 80%;
    }

    .home-header-links {
        margin-bottom: 25px;
        flex-direction: column;
        align-items: center;
    }
}

@media (min-width: 769px) {
    .home-header {
        height: 70vh;
    }

}