.table-title {
    display: flex;
    justify-content: flex;
    align-items: flex;
    color: #2E4993;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 62px;
}

.back-button {
    display: flex;
    justify-content: flex;
    align-items: flex;
    color: black;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 62px;
    padding-top: 15px;
}