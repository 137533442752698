.our-solutions {
    width: 100%;
    padding-top: 100px;
    display: flex;
    justify-content: center;
    background-color: #f7f9fc;
    padding-bottom: 100px;
}

.our-solutions-container {
    width: 90%;
    max-width: 1200px;
}

.os-header-container {
    text-align: center;
    margin-bottom: 40px;
}

.os-header {
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 25px;
    color: #416fdf;
}

.os-header-2 {
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 42px;
    color: #000;
}

.os-header-text {
    font-family: "DM Sans";
    font-weight: 400;
    font-size: 18px;
    margin-top: 10px;
    color: #666;
}

.os-content {
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.os-button-group {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.os-button {
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    background-color: #416fdf;
    border: none;
    border-radius: 5px;
    padding: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.os-button.active {
    background-color: #0a2358;
}

.os-button:hover {
    background-color: #355fbd;
}

.os-card {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

}

@media (min-width: 768px) {
    .os-card {
        width: 75%;
    }
}

/* Responsive styling */
@media (max-width: 768px) {
    .os-content {
        flex-direction: column;
    }

    .os-button-group {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
    }

    .os-card {
        margin-top: 20px;
    }
}

.card-image {
    max-width: 160px;
}


.cards {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-info-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.card-icon-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 15px;
    flex-basis: 150px;
    justify-content: center;
}

.card-image {
    width: 130px;
    height: 130px;
    object-fit: contain;
    transition: transform 0.3s ease;
    justify-content: center;
}


.os-text-header {
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 16px;
    color: #000;
    margin-top: 10px;
}


@media (max-width: 768px) {
    .card-icon-group {
        flex-basis: 100px;
    }

    .card-image {
        width: 150px;
    }

    .os-text-header {
        font-size: 14px;
    }
}


@media (min-width: 1228px) {
    .cards {
        height: 200px;
    }
}

.image-container {
    width: 150px;
    height: 150px;
}