.our-vision-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.media-header {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 20px;
}

.press-releases {
    margin-top: 40px;
    text-align: center;
}

.press-release-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    /* Flexible columns */
    gap: 20px;
    /* Space between items */
}

.release {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease;
}

.release:hover {
    transform: scale(1.05);
}

.article-img-container {
    overflow: hidden;
    border-radius: 10px;
}

.press-release-img {
    width: 100%;
    height: 150px;
    border-radius: 10px;

    transition: transform 0.3s ease;
}

.press-release-img:hover {
    transform: scale(1.1);
}

.release p {
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    text-decoration: none;
}

@media (max-width: 768px) {
    .media-header {
        font-size: 36px;
    }

    .press-release-grid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        /* Adjust for mobile */
    }

    .release p {
        font-size: 14px;
    }
}

.press-release-link {
    text-decoration: none;
}


/* Base styles */
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    padding: 0;
    margin: 0;
}

.article-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}


h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    /* text-align: center; */
}

h2 {
    font-size: 1.75rem;
    margin-top: 30px;
    color: #4a4a4a;
}

p {
    font-size: 1rem;
    margin-bottom: 20px;
    line-height: 1.75;
}

a {
    color: #007BFF;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

ul {
    padding-left: 20px;
    margin-bottom: 20px;
}

ul li {
    margin-bottom: 10px;
}

/* Responsive images */
img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 20px 0;
}

/* Tables */
table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background-color: #f9f9f9;
}

table th,
table td {
    padding: 15px;
    text-align: left;
    border: 1px solid #ddd;
}

table th {
    background-color: #f1f1f1;
}

@media (max-width: 768px) {
    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.5rem;
    }

    p {
        font-size: 0.95rem;
    }

    table th,
    table td {
        padding: 10px;
        font-size: 0.9rem;
    }
}

/* FAQ section */
h4 {
    font-size: 1.2rem;

    margin-top: 20px;
    margin-bottom: 10px;
}

p:last-of-type {
    margin-bottom: 0;
}




.articles {
    padding: 2rem 0;
    /* background-color: #f9f9f9; */
    /* border-radius: 8px; */
}

.articles h1 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
}

.articles ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.articles li {
    background-color: #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 1rem;
    padding: 1rem 1.5rem;
    width: 100%;
    max-width: 350px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.articles li:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.articles li a {
    text-decoration: none;
    font-size: 1.2rem;
    color: #007bff;
    transition: color 0.3s ease;
}

.articles li a:hover {
    color: #0056b3;
}

.articles li p {
    margin-top: 0.5rem;
    font-size: 1rem;
    color: #666;
}

.articles-list li {
    display: block;
}