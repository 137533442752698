.contact-us {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #f7f9fc;
}

.contact-us-container {
    width: 90%;
    max-width: 800px;
    margin: auto;
    background: #ffffff;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.contact-header {
    text-align: center;
    margin-bottom: 30px;
}

.contact-header h2 {
    font-family: "DM Sans", sans-serif;
    font-weight: 700;
    color: #416FDF;
    font-size: 28px;
}

.contact-header h3 {
    font-family: "DM Sans", sans-serif;
    color: #333333;
    font-size: 18px;
    margin-top: 10px;
}

/* Form Fields */
.contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-field {
    font-family: "DM Sans", sans-serif;
    height: 35px;
    padding: 7px;
    border: 1px solid #E5E5E5;
    border-radius: 6px;
    font-size: 14px;
}

.form-field-message {
    font-family: "DM Sans", sans-serif;
    padding: 7px;
    border: 1px solid #E5E5E5;
    border-radius: 6px;
    resize: none;
    font-size: 14px;
}

/* Button Styling */
.submit-button {
    background: #416FDF;
    color: white;
    border: none;
    border-radius: 6px;
    height: 50px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;

}

.submit-button:hover {
    background-color: #3256B5;
}

.submit-button:disabled {
    background-color: #999999;
}

@media (max-width: 768px) {
    .contact-header h2 {
        font-size: 24px;
    }

    .form-field,
    .form-field-message {
        font-size: 14px;
    }
}

@media (min-width: 769px) {
    .contact-us {
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: #f7f9fc;
        padding: 40px 0;
    }
}