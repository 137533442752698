.rb-backgroud{
    background-color: #FFFFFF;
    height: 780px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center top;
    overflow: hidden;
}
.rb-team-card {
    background-color: white;
    border-color: #aaaaaa;
    border-width: 1px;
    width: 300px;
    margin: auto;
    margin-top: 22px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.rb-team-col {
    padding: 0px;
}

.rb-team-member-footer {
    background-color: white;
    border-color: #aaaaaa;
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    height: 110px;
    padding-top: 20px;
}

.rb-team-img {
    width: 278.09px;
    height: 271px;
}
.rb-team-member-name {
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 26px;
    line-height: 42px;
    text-align: center;
    width: 302px;

}
.rb-team-member-status {
    font-family: "DM Sans";
    font-weight: 400;
    font-size: 18px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 0.42px;
}

.our-team-header {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 54px;
    text-align: center;
    letter-spacing: 0.25px;
}

.our-team-container {
    padding-top: 75px;
    padding-bottom: 75px;
}

.our-team {
    display: flex;
    justify-content: center;
    padding-bottom: 2%;
}