.why-rimble {
    background-color: #EAEFF5;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 40px 0;
}

.why-rimble-container {
    width: 100%;
    max-width: 1200px;
    /* Set a max-width for better alignment */
    text-align: center;
}

.why-header-container {
    margin: 0 auto 30px;
}

.why-header {
    font-family: "DM Sans";
    font-weight: 700;
    color: #416FDF;
    font-size: 25px;
    margin: 0;
}

.why-header-2 {
    font-family: "DM Sans";
    color: #000000;
    font-size: 32px;
    font-weight: 700;
    margin: 10px 0;
}

.why-us-icons {
    display: flex;
    flex-wrap: wrap;
    /* Allow wrapping for better responsiveness */
    justify-content: center;
    /* Center the content */
    margin: 0 -15px;
    /* Adjust negative margin for equal spacing */
}

.why-us-icon-container {
    flex: 1 1 30%;
    /* Flex-grow, flex-shrink, and flex-basis to create three columns */
    max-width: 300px;
    /* Set a max-width for each column */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

.why-icon-image {
    width: 100px;
    /* Uniform size for all images */
    height: 100px;
    margin-bottom: 15px;
    /* Space below the image */
}

.why-icon-heading {
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 24px;
    /* Slightly smaller for mobile */
    color: #000000;
    margin: 10px 0;
}

.why-icon-text {
    font-family: "DM Sans";
    font-weight: 400;
    font-size: 16px;
    /* Adjusted for better readability on mobile */
    color: #000000;
    margin: 0;
    padding: 0 10px;
    /* Added padding for text */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .why-header-2 {
        font-size: 24px;
        /* Smaller font on mobile */
    }

    .why-icon-heading {
        font-size: 20px;
        /* Smaller font for headings on mobile */
    }

    .why-icon-text {
        font-size: 14px;
        /* Smaller font for text on mobile */
    }

    .why-us-icon-container {
        flex: 1 1 100%;
        /* Stack icons in a single column on mobile */
        max-width: 100%;
        /* Remove max-width for mobile */
    }
}